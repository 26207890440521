/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/positions' icon='switch' title='Positions' fontIcon='bi-layers' />
      <SidebarMenuItem to='/years' icon='calendar' title='Years' fontIcon='bi-layers' />
      <SidebarMenuItem to='/position-years' icon='calendar' title='Position Years' fontIcon='bi-layers' />
      <SidebarMenuItem to='/states' icon='flag' title='States' fontIcon='bi-layers' />
      <SidebarMenuItem to='/counties' icon='flag' title='Counties' fontIcon='bi-layers' />
      <SidebarMenuItem to='/cities' icon='home' title='Cities' fontIcon='bi-layers' />
      <SidebarMenuItem to='/issues' icon='flag' title='Issues' fontIcon='bi-layers' />
      <SidebarMenuItem to='/candidates' icon='user' title='Candidates' fontIcon='bi-layers' />
      <SidebarMenuItem to='/politicians' icon='user' title='Politicians' fontIcon='bi-layers' />
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<SidebarMenuItem*/}
      {/*  to='/apps/user-management/users'*/}
      {/*  icon='abstract-28'*/}
      {/*  title='User management'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
    </>
  )
}

export {SidebarMenuMain}
