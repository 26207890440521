import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const PositionsPage = lazy(() => import('../modules/positions/IndexPage'))
  const YearsPage = lazy(() => import('../modules/years/IndexPage'))
  const StatesPage = lazy(() => import('../modules/states/IndexPage'))
  const CountiesPage = lazy(() => import('../modules/counties/IndexPage'))
  const CitiesPage = lazy(() => import('../modules/cities/IndexPage'))
  const PositionYearsPage = lazy(() => import('../modules/position_years/IndexPage'))
  const IssuePage = lazy(() => import('../modules/issues/IndexPage'))
  const CandidatePage = lazy(() => import('../modules/candidates/IndexPage'))
  const PoliticianPage = lazy(() => import('../modules/politicians/IndexPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/positions' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='positions/*'
          element={
            <SuspensedView>
              <PositionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='years/*'
          element={
            <SuspensedView>
              <YearsPage />
            </SuspensedView>
          }
        />
          <Route
              path='position-years/*'
              element={
                  <SuspensedView>
                      <PositionYearsPage />
                  </SuspensedView>
              }
          />

        <Route
          path='states/*'
          element={
            <SuspensedView>
              <StatesPage />
            </SuspensedView>
          }
        />

        <Route
          path='counties/*'
          element={
            <SuspensedView>
              <CountiesPage />
            </SuspensedView>
          }
        />

        <Route
          path='cities/*'
          element={
            <SuspensedView>
              <CitiesPage />
            </SuspensedView>
          }
        />

      <Route
          path='issues/*'
          element={
              <SuspensedView>
                  <IssuePage />
              </SuspensedView>
          }
      />

          <Route
              path='candidates/*'
              element={
                  <SuspensedView>
                      <CandidatePage />
                  </SuspensedView>
              }
          />

          <Route
              path='politicians/*'
              element={
                  <SuspensedView>
                      <PoliticianPage />
                  </SuspensedView>
              }
          />

          {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
