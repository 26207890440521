import {AuthModel} from './_models'
import {toast} from "react-toastify";

const AUTH_LOCAL_STORAGE_KEY = 'sne-auth'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  // axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers['Accept_Error_Json'] = true
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
      (config: {headers: {Authorization: string}}) => {
        config.headers['Accept'] = "application/json,text/html"
        const auth = getAuth()
        if (auth && auth.token) {
          config.headers.Authorization = `Bearer ${auth.token}`
        }

        return config
      },
      (err: any) => Promise.reject(err)
  )

  // Add a response interceptor
  axios.interceptors.response.use(
      function (response) {
        if(response.data?.error){
          toast.error(response.data?.status.message)
        }
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
  );
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
